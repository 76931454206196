$primary-color: #003256;
$secondary-color: #279989;
$form-color: white;
$gold: #A27300;
$teal: #279989;
$yellow: #FFC900;


html {
    height: auto;
}

body {  
  font-family: 'Brother', sans-serif;
  color: #484848; 
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#page {
  overflow:hidden;  
}

div, section {
  outline: none;
}

a {
  color: #211A21;
}

a:hover {
  color: $yellow;
}

.button:focus {
  background-color: #279989; 
}

h1, h2, h3, h4 {  
  font-family: 'NoeDisplay', sans-serif;
  letter-spacing: 1px;
  font-weight: 300;
}

h1 {
  font-size:26px;
  line-height: 1.1;
  
  @include breakpoint(large) {
    font-size: 40px;
    margin-bottom: 0;
  }

  &.smaller {
    font-size: 16px;
    @include breakpoint(large) {
      font-size: 22px;
    }
  }

  
}

.large {
  font-size: 22px;
  @include breakpoint(large) {
    font-size: 40px;    
  }    
}

h2 {
  font-size: 25px;  
  line-height: 1.1;

  @include breakpoint(large) {    
    font-size: 29px;
  }
}

h3 {
  font-size: 15px;
  line-height:1.2;

  @include breakpoint(large) {
    font-size: 20px; 
  }
}

h4 {
  font-size: 16px;
  @include breakpoint(large) {
    font-size: 25px; 
  }
}

p {    
  font-weight:400;
  font-size:14px;  
  line-height:1.4;

  @include breakpoint(large) {
    font-size:18px;
  }

  &:last-child {
    margin-bottom:0;
  }
}

.alt-font {
  font-family: 'Brother', sans-serif!important;
}

p.large {
  font-size: 23px;
  line-height: 1.6;
}

.small {
  font-size: 11px;
}

.light {
  color: #707070;
}

a {
  font-size: 16px;
  transition: color 0.2s ease;
  @include breakpoint(large) {
    font-size: 20px;
  }

  &:hover {
    color: #279989;
  }
}


.bg-primary {  
  background: $primary-color;
}

.bg-secondary {
  background: $secondary-color;
}

.bg-yellow {
  background-color:#E1DFD3;
}

.bg-light-grey {
  background: #D8D8D8;
}

.bg-grey {
  background: #F3F3F3;
}

.bg-light-blue {
  background: #007C89;
}

.bg-white {
  background: #fff;
}

.primary {
  color: $primary-color!important;
}

.secondary {
  color: $secondary-color!important;
}

.white {
  color: white;
}

.light-blue {
  color: #6F9BA4;
}

.yellow {
  color: $yellow;
}

.grey {
  color: #5F6369;
}

.shadow {
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.88);
}

.relative {
  position:relative;
}


.v-align-bottom {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.v-align-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.padding-side {
  padding: 4% 7%;

  @include breakpoint(large) {
    padding: 0 15%;    
  }
}


.mobile-order-1 {
  order: 1;
  @include breakpoint(large) {
    order: initial;
  }
}

.mobile-order-2 {
  order: 2;
  @include breakpoint(large) {
    order: initial;
  }
}


.mobile-hide {
  display:none;
  @include breakpoint(large) {
    display:block;
  }
}

.spacing10 {
  height: 10px;
}

.spacing20 {
  height: 20px;
}

.spacing40 {
  height: 20px;
  @include breakpoint(large) {
    height: 40px;
  }
}

.spacing60 {
  height: 25px;
  @include breakpoint(large) {
    height: 60px;
  }
}

.spacing80 {
  height: 30px;
  @include breakpoint(large) {
    height: 80px;
  }
}

.spacing100 {
  height: 35px;
  @include breakpoint(large) {
    height: 100px;
  }
}


.fade-up {
  transform: translateY(15px) scale3d(1.05, 1.05, 1.0);
  opacity: 0;
  transition: 1s all ease;
  filter:blur(2px);
  &.active {
    transform: translateY(0) scale3d(1.0, 1.0, 1.0);
    filter:blur(0);
    opacity: 1;
  }
}

.fade-down {
  transform: translateY(-15px)scale3d(1.05, 1.05, 1.0);
  opacity: 0;
  transition: 1s all ease;
  filter:blur(2px);
  &.active {
    transform: translateY(0) scale3d(1.0, 1.0, 1.0);
    opacity: 1;
    filter:blur(0);
  }
}

.fade-left {
  transform: translateX(-30px)scale3d(1.05, 1.05, 1.0);
    opacity: 0;
    transition: 1s all ease;
    filter:blur(2px);
    &.active {
      transform: translateX(0) scale3d(1.0, 1.0, 1.0); 
      opacity: 1;
      filter:blur(0);
    }
}

.fade-right {
  transform: translateX(30px)scale3d(1.05, 1.05, 1.0);
    opacity: 0;
    transition: 1s all ease;
    filter:blur(2px);
    &.active {
      transform: translateX(0) scale3d(1.0, 1.0, 1.0);
      opacity: 1;
      filter:blur(0);
    }
}
.fade-delay {
  transition-delay: 1s;
}

.fade-delay2 {
  transition-delay: 2s;
}

.fade-delay3{
  transition-delay: 3s;
}

label {
  color: black;
}

input, select, textarea {
  background-color: white!important;
  margin:0 0 15px;
  border: 0!important;
  box-shadow: none!important;
  outline: 0;
  border-style:solid;
  border-width:0;
  border-bottom: 2px solid $primary-color!important;
  font-size: 16px;  
  color:black!important;
  padding-left: 10px!important;
  height: 50px;
  width: 100%;
  padding-bottom:7px;
  margin-bottom: 25px;

}

option {
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  
  width: 15px;
  height: 15px;
  background-color: white!important;
  border-radius: 50%;
  vertical-align: top;
  border: 1px solid $form-color!important;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position:relative;
  top: 6px;
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  background-color: #00ADDC!important;
}

input[type="radio"] {
  top: 5px;
}

button[type="submit"] {
  
  line-height: 1;
  margin-top: 10px;
  background-color: $primary-color;
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
}

textarea {
  min-height: auto;
}

.button {  
  
  border-radius: 3px;
  padding: 9px 20px;
  color: white;
  font-size: 11px;
  background-color: $primary-color;
  

  @include breakpoint(large) {
    padding: 9px 20px;
    font-size: 14px;
  }

  &:hover {
    background-color:#279989;
  }

  &-white {
    background-color: white;
    color: $primary-color;
  }
}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black!important;
}

hr {
  display: inline-block;
  transition: 1s all ease;
  transition-delay: 0.9s;
  margin: 8px 0;
  &.gold {
    border: 0;
    border-bottom: 1px solid $gold;
  }

  &.line-overflow {
    width: 20%;    
  }
}

.active hr.line-overflow {
  width: 150%;
}

.center {
  text-align: center;
}

select option { color: black; }

.logo {
  max-width: 190px;
  position: relative;  
}


.hero {

  margin-top: 90px;

  width: 100%;
  // height: 100vh;
  position: relative;

  .hero-base {

  }
  .hero-caption, .hero-overlay {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
  }
  .hero-overlay {

  }

}






.register {

  opacity: 0;  
  transition: 0.8s all ease;
  z-index: 9;
  margin-top: 10px;

  @include breakpoint(large) {  
    margin-top: 0;
  }

  &.hidden {
    opacity:0!important;
  }

}






.flex {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint(large) {  
    flex-wrap: no-wrap;
  }
  
}

.one-third {
  width: 100%;  
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include breakpoint(large) {
    width: 33.33%;
  }
}

.v-half {
  position: relative;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

}

footer {
  color: white;
  padding: 45px 0 0;  
  position:relative;
  z-index: 1;

  @include breakpoint(large) {
    padding: 120px 0 0;  
  }

  .footer-logo {
    width: 50%;
    @include breakpoint(large) {
      width: 70%;
    }
  }

  .location-map {
    width: 80%;
    margin-top: 30px;
  }

  .row {
    max-width: 85rem;
  }
}

.consent {
  input {
    float: left;
    margin-right: 12px;

    @include breakpoint(large) {}
  }

  label {
    margin-left: 30px;
    margin-top:0;
  }

}

.no-margin {
  margin: 0!important;
}

.column, .columns {
  padding-left: 1.2rem;
  padding-right: 1.2rem; 
  @include breakpoint(large) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}


nav {
  height: 90px;
  background-color:white;
  display: flex;
  align-items:center;
  padding: 0 20px;
  justify-content: space-between;
  z-index: 20;
  position: fixed;
  top: 0;
  width: 100%;

  @include breakpoint(large) {
    padding: 0 40px;
  }
  
  
  .nav-items {
    position:fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -100vh;
    background-color:$primary-color;
    text-align: center;
    transition: 0.4s top ease;

    &.active {
      top: 0;
      height: 100vh;
    }

    .close {
      color: white;
      position:absolute;
      right: 20px;
      top: 20px;
    }
    a {      
      color: white;
      letter-spacing: 1px;
      font-size: 14px;
      display: block;
      margin: 7vh 0;
      transition: 0.2s all ease;
      &:hover {
        color: $secondary-color;
      }

      &.nav-register {       
       color: white;
      }

      @include breakpoint(large) {
        color: $primary-color;
      }
    }

    .button {
      display: inline-block;
      margin-top: 0;
      @include breakpoint(large) {
        
      }
    }

    @include breakpoint(large) {
      background-color:transparent;
      position:static;
      width: auto;
      height: auto;
      text-align: left;
      // padding: 0 50px;

      .close {
        display: none;
      }

      a {
        margin: 0;
        margin-left: 30px;
        display: inline-block;
        
        &.register {
         display: inline-block;
         width: auto;
         margin: 0;
        }
      }
    }
    
  }
}

.home .nav-home, .masterplan .nav-masterplan, .location .nav-location, .real-estate .nav-real-estate, .contact .nav-contact {
  color: $secondary-color;
  font-weight: 600;
}


$tcon-size: 30px !default;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
) !default;
.tcon {
  position: absolute;
  top: 30px;
  right: 20px;
  float:right;
  z-index: 99999;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  @include breakpoint(large) {
    display: none;    
  }

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: 4px;
  width: $tcon-size;
  // border-radius: $tcon-menu-radius;
  transition: $tcon-transition;
  background: $primary-color;  
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;

  &::before,
  &::after {
    @include tcon-menu-lines;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 5) center;
    width: 100%;
  }

  &::before {
    top: ($tcon-size / 4);
  }

  &::after {
    top: -8.5px;
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.8, .8, .8);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}



.slides-holder {
  position:relative;
  img {
    border-radius:30px;
  }
}

.arrows {
  position: absolute;  
  z-index: 15;
  top: 50%;
  transform: translateY(-50%);  
  right: 0;
  width: 100%;

  button {    
    text-indent: -9999px;    
    overflow:hidden;    
    display: inline-block;
    transform-origin: center center;    
    float:left;
    margin-left: 0;    
    background: url('/assets/images/arrow.png') center center no-repeat;
    background-size: cover;
    width: 30px; 
    height: 30px; 
    @include breakpoint(large) {
      margin-left: -15px;    
      width: 45px; 
      height: 45px;
    }

    &.slick-next {      
      transform: rotate(180deg);
      transform-origin: center center;
      float:right;
      margin-right: 0;
      @include breakpoint(large) {
        margin-right: -15px;
      }
    }
  }

}



#intro {
  z-index: 19;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
  object-fit: cover;  
  margin-top: 90px;
  @include breakpoint(large) {
    margin-top: 0;
    height: 100vh;
  }
}

footer {
  background: $primary-color;
  padding: 80px 0;
  position: relative;
  .footer-links {
    .white {
      font-size: 15px;
      display: block;
      margin-bottom: 10px;
    }
  }
}

.newsletter-signup {
  display: flex;
  #newsletter_email {
    
  }
  #newsletter_submit {
    flex-basis: 30%;
    background: $teal!important;
    font-size: 11px;
    @include breakpoint(large) {
      font-size: 14px;
    }
  }
  input {
    border: 0!important;
    border-radius: 0;
    background: white!important;
    margin: 0;
    padding: 0;
    height: 40px;
    font-size: 14px;

  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:grey!important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:grey!important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color:grey!important;
  }
}

.footer-border {
  display: block;
}
.text-link {
  color: #A27300;
  font-size: 16px;
  font-weight: bold;
}

.home-boxes {
  margin-top: -6px;
  position: relative;
  padding-top: 80px;
  padding-bottom: 40px;
  background-image: url('/assets/images/home/shape.png');
  background-repeat: repeat-x;
  background-position: center top;
  background-size: auto 40%;

  @include breakpoint(large) {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  h2 {
    margin-top: 5px;
    min-height: 80px;
  }
  .box-des {
    min-height: 110px;
  }
  .box-content {
    padding: 40px 30px 20px;
    margin-bottom: 30px;
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
  .columns:nth-child(1) .box-content {
    background: url('/assets/images/home/bg1.jpg') center center no-repeat;
    background-size: cover;
  }

  .columns:nth-child(2) .box-content {
    background: url('/assets/images/home/bg2.jpg') center center no-repeat;
    background-size: cover;
  }

  .columns:nth-child(3) .box-content {
    background: url('/assets/images/home/bg3.jpg') center center no-repeat;
    background-size: cover;
  }
}

.zdx {
  z-index: 2;
}

.uC {
  width: 100%;
  padding: 7%;
  float: right;
  position: relative;
  z-index: 1;
  background: white;
  @include breakpoint(large) {
    width: 75%;
  }
}

.loc-img1 {
  width: 43%;
  display: block;
  position: relative;
  float: left;
  @include breakpoint(large) {
    width: 200px;
    float: none;
  }
}

.loc-img2 {
  width: 57%;
  display: block;
  @include breakpoint(large) {
    width: 70%;
  }
}

.loc-img3 {
  margin-top: -25%;
}

.overlap-content {
  margin-top: 0;
  @include breakpoint(large) {
    margin-top: -100px;
  }
}

.bg-pattern {
  padding: 15%;
  
  h2 {
    line-height: 1.6;
  }
}

.location .bg-pattern, .masterplan .bg-pattern {
  background: url('/assets/images/location/bg-pattern.png') center center no-repeat;
  background-size: cover;  
}

.vision .bg-pattern {
  background: url('/assets/images/vision/bg-pattern.png') center center no-repeat;
  background-size: cover;  
}


.loc-img8 {
  width: 60%;
  float: right;
}

.slide-content {
  position: relative;
  margin-top: 0;
  width: 80%;
  float:right;
  padding: 10%;

  transition: 1s all ease;
  opacity: 0;
  top: 10px;
  transition-delay: 0.3s;

  @include breakpoint(large) {
    margin-top: -100px;
    width: 60%;
  }
}

.swiper-slide-active {
  .slide-content {
    opacity: 1;
    top: 0;
  }
}

.swiper-button-next, .swiper-button-prev {
  background: url('/assets/images/slide-arrow.png') center center no-repeat;
  background-size: 45px 45px;
  width: 45px;
  height: 45px;

  &:after {
    display: none;
  }
}

.swiper-nav {
  position: absolute;
  width: 100%;
  top: 20px;
  left: 0;

  @include breakpoint(large) {
    width: 80px;
    top: 60.8%;
    left: 12.6%;    
  }
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
  transform: rotate(180DEG);
}

.uTable {

  width: 100%;
  @include breakpoint(large) {
    width: 90%;
  }
  
  td {
    
    padding: 15px 0;    
  }

  &-white {
    tr {
      border-bottom: 1px solid white;
      td {
        color: white;
      }
    }
  }

  &-gold {
    tr {
      border-bottom: 1px solid $gold;
      td {
        color: #4F4F4F;
      }
    }
  }
}


.phase1 {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 90%;
    background-color: $primary-color;
    z-index: -1;
  }
}

.non-hero {
  height: 90px;
}

.form-img {
  margin-top: 0;
  @include breakpoint(large) {
    margin-top: -200px;
  }
}

p a {
  color: $gold;
  font-size: inherit;
}

#thank {
  display: none;
}