@font-face {
    font-family: 'NoeDisplay';
    src: url('../fonts/NoeDisplay-Black.eot');
    src: url('../fonts/NoeDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NoeDisplay-Black.woff2') format('woff2'),
        url('../fonts/NoeDisplay-Black.woff') format('woff'),
        url('../fonts/NoeDisplay-Black.ttf') format('truetype'),
        url('../fonts/NoeDisplay-Black.svg#NoeDisplay-Black') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brother';
    src: url('../fonts/Brother-1816-Book.eot');
    src: url('../fonts/Brother-1816-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Brother-1816-Book.woff2') format('woff2'),
        url('../fonts/Brother-1816-Book.woff') format('woff'),
        url('../fonts/Brother-1816-Book.ttf') format('truetype'),
        url('../fonts/Brother-1816-Book.svg#Brother-1816-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brother-Light';
    src: url('../fonts/Brother-1816-Light.eot');
    src: url('../fonts/Brother-1816-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Brother-1816-Light.woff2') format('woff2'),
        url('../fonts/Brother-1816-Light.woff') format('woff'),
        url('../fonts/Brother-1816-Light.ttf') format('truetype'),
        url('../fonts/Brother-1816-Light.svg#Brother-1816-Light') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}